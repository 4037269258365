export const concernCategoryMappings = {
    "milia": {
        "categoryId": "v0bfhhv5Nzdluz9qUzUBHg",
        "name": "Milia"
    },
    "fine lines": {
        "categoryId": "HDyBOev94rlwv3ID9sMfPQ",
        "name": "Fine lines"
    },
    "breakout": {
        "categoryId": "PMFrhbNv95jcKIpAGr5m3w",
        "name": "BREAKOUT"
    },
    "oiliness": {
        "categoryId": "RymM1M2zmDSlv3pdi7_oKA",
        "name": "Oiliness"
    },
    "pigmentation": {
        "categoryId": "DuNlA8G9tfUd7PAuAkXyvg",
        "name": "Pigmentation"
    },
    "comedones": {
        "categoryId": "QxjriD-uyeqPMjtTo4Id9g",
        "name": "Comedones"
    },
    "deep lines": {
        "categoryId": "KeFY1IbXdRjO8p8ZvuxlYw",
        "name": "Deep lines"
    },
    "sensitivity/redness": {
        "categoryId": "0Oo66h-1wtvTBOvYscspUQ",
        "name": "Sensitivity/redness"
    },
    "dehydration": {
        "categoryId": "5wwlbTN6aNqjRP_nUdvGZw",
        "name": "Dehydration"
    }
}
