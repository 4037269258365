import React, {useState, useEffect, useRef} from "react";
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import Nav from './../Nav';
import {axiosNodeClient} from '../../variables';

import FmpLandingPage from "./FmpLandingPage";

import derm_pro_logo from './../../images/fmp_logo.png';
import {API_URL} from "../../variables";

function FmpLoginPage() {

    // state here
    const [ branchesArr, setBranchesArr ] = useState();
    const [ therapistObj, setTherapistObj ] = useState();
    const [ loginFailed, setLoginFailed] = useState(false);
    const [ loadingState, setLoadingState ] = useState(true);

    // refs here
    const inputElementRef = useRef(null);
    const loginSubmitBtn = useRef(null);
    const branch_id = useRef(null);
    const branch_name = useRef(null);
    const therapist_pin = useRef(null);
    const api_key = useRef(null);

    async function doApiAuthentication() {
        try {
            // axios.defaults.baseURL = API_URL;
            // const res = await axiosNodeClient.get('/api-auth');
            // axiosClient.defaults.headers['Authorization'] = res.data.token;

            // localStorage.setItem('auth_code', res.data.token);
            getAllBranches()

        } catch(err) {
            console.log(err.message)
        }
    }

    // functions to call endpoints 
    // async function getAllBranches() {
    //     try {
    //         let res = await axiosClient.get(`/api/collections/branches/records?perPage=100`);
    //         res = res.data.items;

    //         setBranchesArr(res);
    //         setLoadingState(false);

    //         branch_id.current = res[0].phorest_branch_id;
    //     } catch(err) {
    //         console.log(err.message)
    //     }
    // }

    async function getAllBranches() {
        try {
            let res = await axiosNodeClient.get('get-all-branches');
            res = JSON.parse(res.data.data);

            setBranchesArr(res);
            setLoadingState(false);

            branch_id.current = res[0].phorest_branch_id;
            branch_name.current = res[0].name;
        } catch(err) {
            //console.log(err.message)
        }
    }

    async function getTherapist() {
        setLoadingState(true);
        // axiosClient.defaults.params = {"filter": `(pin='${therapist_pin.current}' && phorest_branch_id='${branch_id.current}')`}

        let res = await axiosNodeClient.post(`/search-therapist`, 
            JSON.stringify({
                branch_id: branch_id.current,
                therapist_pin: therapist_pin.current
            }),
            {
                headers: {
                    'content-type': 'application/json'
                }
            }
        );

        res = JSON.parse(res.data.data);

        if (res) {
            // const cleanedTherapistObj = {
            //     fn: res.first_name,
            //     ln: res.last_name,
            //     pb_i: res.phorest_branch_id,
            //     ps_i: res.phorest_staff_id,
            // }

            setTherapistObj(res);

            localStorage.setItem('fmpfp_therapist_obj', JSON.stringify(res));
            localStorage.setItem('b_n', branch_name.current)

            // axiosClient.defaults.params = {};
            return true;
        } else {
            setLoginFailed(true);
            setLoadingState(false);

            setTimeout(() => {
                setLoginFailed(false);
            }, 3000);
        }

        return false;
    }

    // GET all branches on component mount
    useEffect(() => {
        doApiAuthentication()
        branch_name.current = "";
    }, []);

    useEffect(() => {
        
    }, []);

    function handleBranchesDropdown(evt) {
        branch_id.current = evt.target.value;
        branch_name.current = evt.target.options[evt.target.selectedIndex].textContent;
    }

    function handlePstPinInput(evt) {
        therapist_pin.current = evt.target.value;
    }

    function handleLoginSubmit(evt) {
        evt.preventDefault();
        getTherapist();
    }

    function handleLoginBtn(status) {
       //loginSubmitBtn.current.disabled = status;
       //setLoadingState(true);
    }

    function populateBranchesDropdown() {
        if (branchesArr) {
            return branchesArr.map(branchObj => {
                return (
                    <option className="fmpfp_branches_option" value={branchObj.phorest_branch_id} key={uuidv4()}>{branchObj.name}</option>
                )
            })
        }
    }


    const loginFailedModal = <div className="fmpfp_login_failed_container">
        <p id="fmpfp_login_failed_text">Invalid Branch / PIN combination</p>
    </div>

    if (typeof therapistObj === "undefined") {
        return (
            <div className="fmpfp_login_page">
                {loginFailed ? loginFailedModal : null}
                <div id="fmpfp_login_container">
                    <div id="fmpfp_login_header_container">
                        <h1 id="fmpfp_login_title">Log in to your account.</h1>
                    </div>
                    <div id="fmpfp_login_form_container">
                        {loadingState ? <i className="fas fa-circle-notch fa-spin fa-2xl"></i> :
                            <form id="fmpfp_login_form" onSubmit={(e) => handleLoginSubmit(e)}>
                                <img className="fmpfp_logo" src={derm_pro_logo} />
                                <p id="fmpfp_login_text">Login</p>
                                <div className="fmpfp_login_input_container">
                                    <label className="fmpfp_input_labels" id="fmpfp_branch_select_title" htmlFor="fmpfp_branch_select">Branch</label>
                                    <select id="fmpfp_branch_select" onChange={(e) => handleBranchesDropdown(e)} className="fmpfp_login_inputs">
                                        {populateBranchesDropdown()}
                                    </select>
                                </div>
                                <div className="fmpfp_login_input_container">
                                    <label className="fmpfp_input_labels" id="fmpfp_pst_pin_title" htmlFor="fmpfp_pst_pin">Therapist PIN</label>
                                    <input ref={inputElementRef} type="password" id="fmpfp_pst_pin" onChange={(e) => handlePstPinInput(e)} className="fmpfp_login_inputs"></input>
                                </div>
                                <button ref={loginSubmitBtn} type="submit" className="fmpfp_login_submit_btn">sign in</button>
                                <div id="fmpfp_tos_container">
                                    <a className="fmpfp_tos_item" href="https://www.unileverus.com/terms/termsofuse.html" target="__blank">Terms Of Use</a>
                                    <a className="fmpfp_tos_item" href="https://www.dermalogica.com/privacy-notice/privacy-notice,default,pg.html" target="__blank">Privacy Notice</a>
                                    <a className="fmpfp_tos_item" href="https://www.dermalogica.com/privacy-query/privacy-query,default,pg.html" target="__blank">Do Not Sell My Personal Information</a>
                                </div>
                            </form>
                        }   
                    </div>
                </div>
            </div>
        );
    } else {
        return <FmpLandingPage />
    }
}

export default FmpLoginPage;