import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import face_img from "../images/face_template.png";
import Draggable from "react-draggable";
import breakout_icon from "../images/icons/Breakout_Activity.png";
import fine_lines_icon from "../images/icons/Fine_Lines.png";
import milia_icon from "../images/icons/Milia.png";
import Oiliness_icon from "../images/icons/Oiliness.png";
import Pigmentation_icon from "../images/icons/Pigmentation.png";
import comedones_icon from "../images/icons/Comedones.png";
import deep_lines_icon from "../images/icons/Deep_Lines.png";
import sensitivity_redness_icon from "../images/icons/Sensitivity_Redness.png";
import dehydration_icon from "../images/icons/Dehydration.png";
import text_icon from "../images/icons/Text_Input.png";
import mic_icon from "../images/icons/Mic_Input.png";

import { toPng } from 'html-to-image';

function SkinGoals({ setSkinGoalsDesc }) {

  const draggableIconsMapOne = new Map([
    ["breakout", breakout_icon],
    ["fine lines", fine_lines_icon],
    ["milia", milia_icon],
  ]);

  const draggableIconsMapTwo = new Map([
    ["oiliness", Oiliness_icon],
    ["pigmentation", Pigmentation_icon],
    ["comedones", comedones_icon],
  ])

  const draggableIconsMapThree = new Map([
    ["deep lines", deep_lines_icon],
    ["sensitivity/redness", sensitivity_redness_icon],
    ["dehydration", dehydration_icon]
  ])

  const [icons, setIcons] = useState();
  const [isText, setIsText] = useState(true);

  const [iconContainerOne, setIconContainerOne] = useState();
  const [iconContainerTwo, setIconContainerTwo] = useState();
  const [iconContainerThree, setIconContainerThree] = useState();


  const captureImg = useRef(null)
 
  let initalTemplateWidth;

  const [droppedIconsArr, setDroppedIconsArr] = useState(() => {
    try {
      const storedIcons = localStorage.getItem("skin_icons");
      return storedIcons ? JSON.parse(storedIcons) : [];
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return [];
    }
  });

  const container = useRef(null);
  const draggableArea = useRef(null);
  const screenPos = useRef({
    top: 0,
    left: 0,
    x: 0,
    y: 0
  })
  const skinGoalsContainer = useRef(null);

  const [currIconPage, setCurrIconPage] = useState(1);
  const goalsRef = useRef(null);

  useEffect(() => {
    const existingSkinGoalsDesc = localStorage.getItem("skin_goals_desc");

    if (existingSkinGoalsDesc) {
      setSkinGoalsDesc(existingSkinGoalsDesc);
      goalsRef.current.value = existingSkinGoalsDesc;
    }
  }, [])

  // on component mount
  useEffect(() => {
    const skin_icons = JSON.parse(localStorage.getItem("skin_icons"));
    
    if (!skin_icons || skin_icons.length === 0) {
      localStorage.removeItem('skin_goals_img')
    }
  }, [])

  // on draggableArea state update
  useEffect(() => {
    if (draggableArea.current) {
      generateIcons();

      initalTemplateWidth = draggableArea.current.offsetWidth;
      window.addEventListener("resize", handleTemplateResize);

      return () => {
        window.removeEventListener("resize", handleTemplateResize);
      };
    }
  }, [draggableArea]);

  function mouseDownHandler(e) {
    screenPos.current = {
      // The current scroll
      left: skinGoalsContainer.current.scrollLeft,
      top: skinGoalsContainer.current.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    }

    document.addEventListener('touchmove', mouseMoveHandler);
    //document.addEventListener('mouseup', mouseUpHandler);
  }

  function mouseMoveHandler(e) {
    const dx = e.clientX - screenPos.current.x;
    const dy = e.clientY - screenPos.current.y;

    // Scroll the element
    window.pageYOffset = screenPos.current.top - dy;
    skinGoalsContainer.current.scrollLeft = screenPos.current.left - dx;
  }


  function getFaceTemplateBounds() {
    const faceTemplateBounds = {
      top: draggableArea.current.getBoundingClientRect().top,
      bottom: draggableArea.current.getBoundingClientRect().bottom,
      left: draggableArea.current.getBoundingClientRect().left,
      right: draggableArea.current.getBoundingClientRect().right
    }

    return faceTemplateBounds;
  }

  function handleCopy(evt) {
    const concernIconBounds = evt.target.getBoundingClientRect();
    const faceTemplateBounds = getFaceTemplateBounds();
    let iconSubVal = window.innerWidth > 500 ? 0 : 0;
    let boundsSubVal = window.innerWidth > 500 ? 0 : 0;
  
    if (concernIconBounds.top >= faceTemplateBounds.top 
        && concernIconBounds.top <= faceTemplateBounds.bottom
        && concernIconBounds.left >= faceTemplateBounds.left
        && concernIconBounds.left <= faceTemplateBounds.right - boundsSubVal) {
  
      const iconData = {
        src: evt.target.src,
        style: {
          position: "absolute",
          top: `${(concernIconBounds.top - faceTemplateBounds.top) - 11}px`, 
          left: `${(concernIconBounds.left - faceTemplateBounds.left) + iconSubVal}px`
        },
        className: "fmpfp_face_template_concern_icons fmpfp_placed_icons"
      };
      setDroppedIconsArr(current => [...current, iconData]);

      // save icon name in localstorage
      let droppedIconsTextArr;

      try {
       droppedIconsTextArr = JSON.parse(localStorage.getItem("skin_icons_text_arr")) || [];
      } catch(err) {
        droppedIconsTextArr = []
      }

      droppedIconsTextArr.push(evt.target.getAttribute("concern-key"));
      droppedIconsTextArr = droppedIconsTextArr.filter((text) => text != null); // filter out null or undefined
      localStorage.setItem("skin_icons_text_arr", JSON.stringify(droppedIconsTextArr));

      generateIcons();
      handleImgSave();
    } else {
      generateIcons();
    }
  }

  // Effect to store the data whenever it changes
 // When saving to localStorage
  useEffect(() => {
    try {
      localStorage.setItem("skin_icons", JSON.stringify(droppedIconsArr));
    } catch (error) {
      console.error("Error writing to localStorage:", error);
    }
  }, [droppedIconsArr]);

  // When loading from localStorage, convert data to React elements
  const droppedIcons = droppedIconsArr.map(iconData => (
    <img src={iconData.src} className={iconData.className} style={iconData.style} key={uuidv4()} />
  )); 

  function handleImgSave() {
    toPng(captureImg.current, { cacheBust: true, })
    .then((dataUrl) => {
      const link = document.createElement('a')
      localStorage.setItem('skin_goals_img', dataUrl)
    })
    .catch((err) => {
      //console.log(err)
    })
  }

  function handleTemplateResize(evt) {
    const newTemplateImgWidth = draggableArea.current.offsetWidth;

    if (initalTemplateWidth !== newTemplateImgWidth) {
      resetIcons();
      initalTemplateWidth = draggableArea.current.offsetWidth;
    }
  }

  function toggleInput(evt) {
    if (evt.target.id === "fmpfp_skin_goals_icon_mic") {
      setIsText(false);
    } else {
      setIsText(true);
    }
  }

  function generateIcons() {
    let iconsArrOne = [];
    let iconsArrTwo = [];
    let iconsArrThree = [];

    for (let [key, value, index] of draggableIconsMapOne.entries()) {
      iconsArrOne.push(
        <div id="fmpfp_icon_title_pair" ref={container} key={uuidv4()} className={`draggable draggable_${index}`}>
          <Draggable offsetParent={draggableArea.current} onStop={handleCopy} scale={1}>
            <img src={value} className="fmpfp_face_template_concern_icons" concern-key={key} key={uuidv4()} />
          </Draggable>
          <p className="fmpfp_icon_title">{key}</p>
        </div>
      )
    }

    for (let [key, value, index] of draggableIconsMapTwo.entries()) {
      iconsArrTwo.push(
        <div id="fmpfp_icon_title_pair" ref={container} key={uuidv4()} className={`draggable draggable_${index}`}>
          <Draggable offsetParent={draggableArea.current} onStop={handleCopy} scale={1}>
            <img src={value} className="fmpfp_face_template_concern_icons" concern-key={key} key={uuidv4()} />
          </Draggable>
          <p className="fmpfp_icon_title">{key}</p>
        </div>
      )
    }

    for (let [key, value, index] of draggableIconsMapThree.entries()) {
      iconsArrThree.push(
        <div id="fmpfp_icon_title_pair" ref={container} key={uuidv4()} className={`draggable draggable_${index}`}>
          <Draggable offsetParent={draggableArea.current} onStop={handleCopy} scale={1}>
            <img src={value} className="fmpfp_face_template_concern_icons" concern-key={key} key={uuidv4()} />
          </Draggable>
          <p className="fmpfp_icon_title">{key}</p>
        </div>
      )
    }

    setIconContainerOne(iconsArrOne);
    setIconContainerTwo(iconsArrTwo);
    setIconContainerThree(iconsArrThree)
  }

  function resetIcons() {
    setDroppedIconsArr([]);
    localStorage.removeItem('skin_goals_img')
    localStorage.removeItem('skin_icons_text_arr')
  }

  // const droppedIcons = droppedIconsArr.map(el => {
  //   return el;
  // })

  function handleIconPageLoad() {
    if (currIconPage == 1) {
      return iconContainerOne;
    } else if (currIconPage == 2) {
      return iconContainerTwo;
    } else if (currIconPage == 3) {
      return iconContainerThree;
    }
  }

  function handleIconPageToggle(pageVal) {
    setCurrIconPage(pageVal);
  }

  function handleIconPageArrows(arrowVal) {
    if (arrowVal == "left") {
      if (currIconPage == 1) {
        setCurrIconPage(3);
      } else {
        setCurrIconPage(currIconPage - 1);
      }
    } else if (arrowVal == "right") {
      if (currIconPage == 3) {
        setCurrIconPage(1);
      } else {
        setCurrIconPage(currIconPage + 1);
      }
    }
  }

  // write function to handle image

  return (
    <div id="fmpfp_skin_goals" ref={skinGoalsContainer} onTouchStart={mouseDownHandler}>
      <label id="fmpfp_skin_goals_title">my skin goals</label>
      <div id="fmpfp_skin_goals_input_container">
        {isText ? (
          <input id="fmpfp_skin_goals_input" ref={goalsRef} type="text" placeholder="enter skin goals" onBlur={(e) => setSkinGoalsDesc(e.target.value)} />
        ) : (
          <div className="boxContainer">
            <div className="box box1"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box5"></div>
          </div>
        )}
        <div id="fmpfp_skin_goals_icon_container">
          {/* <img
            className={isText ? "fmpfp_skin_goals_icon" : "fmpfp_hide_icons"}
            id="fmpfp_skin_goals_icon_text"
            src={text_icon}
            onClick={toggleInput}
          />
          <img
            className={isText ? "fmpfp_skin_goals_icon" : "fmpfp_hide_icons"}
            id="fmpfp_skin_goals_icon_mic"
            src={mic_icon}
            onClick={toggleInput}
          /> */}
          {isText ? null : (
            /* <button className="fmpfp_skin_goals_toggle" onClick={toggleInput}>
              submit
            </button> */

            <p></p>
          )}
        </div>
      </div>
      <div id="fmpfp_face_template_container">
        <span ref={captureImg}>
          <img id="fmpfp_face_template" src={face_img} ref={draggableArea} />
          {droppedIconsArr.length > 0 ? droppedIcons : null}
        </span>
        <button className="fmpfp_skin_goals_toggle" onClick={resetIcons}>
          reset icons
        </button>
        <div id="fmpfp_face_icon_container">
          <i className="arrow left" onClick={() => handleIconPageArrows('left')}></i>
          {handleIconPageLoad()}
          <i className="arrow right"onClick={() => handleIconPageArrows('right')}></i>
        </div>
        <div id="fmpfp_face_icon_page_number_container">
          <p 
            className={
              currIconPage == 1 ? 
              "fmp_face_icon_page_number_selected fmpfp_face_icon_page_number" : 
              "fmpfp_face_icon_page_number"
            } onClick={() => handleIconPageToggle(1)}
          >1</p>
          <p 
            className={
              currIconPage == 2 ? 
              "fmp_face_icon_page_number_selected fmpfp_face_icon_page_number" : 
              "fmpfp_face_icon_page_number"
            } onClick={() => handleIconPageToggle(2)}
          >2</p>
          <p 
            className={
              currIconPage == 3 ? 
              "fmp_face_icon_page_number_selected fmpfp_face_icon_page_number" : 
              "fmpfp_face_icon_page_number"
            } onClick={() => handleIconPageToggle(3)}
          >3</p>
        </div>
      </div>
    </div>
  );
}

export default SkinGoals;
