import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
// import "survey-core/defaultV2.min.css";

import axios from 'axios';
import {axiosNodeClient} from '../../variables';

import {API_URL} from "../../variables";
import { FRONTEND_URL } from "../../variables";

import { buildTreatmentAuthJson } from "../../data/json-forms/Treatment_Auth_Form";
import GeneratePreAuth from '../GeneratePreAuth';

// import { surveyTheme } from '../../data/survey_theme';
// StylesManager.applyTheme(surveyTheme);

function TreatmentAuth(props) {
  const isExistingClient = useRef(false);
  const clientObj = useRef(null);
  const phorestClientObj = useRef(null);
  const isExistingClientPhorest = useRef(false);
  const clientSessionId = useRef(null);
  const redirect = useNavigate();
  const [clientNamesArr, setClientNamesArr] = useState([])
  const branch_id = useRef(null);
  const [formComplete, setFormComplete] = useState(false)
  // const [extractEmailConsent, setExtractEmailConsent] = useState(false)
  // const [emailConsented, setEmailConsented] = useState(false)
  const [clientSubmissionData, setClientSubmissionData] = useState({})
  const [loading, setLoading] = useState(false);
  const [submissionState, setSubmissionState] = useState('initial');

  useEffect( () => {
    const pstObj = JSON.parse(localStorage.getItem('fmpfp_therapist_obj'));

    if (pstObj) {
      branch_id.current = pstObj.pb_i;
    }
    
    const getClientNames = async () => {
      try {
        // const res = await axiosClient.get(`/api/collections/sessions/records?filter=(phorest_branch_id='${phorest_branch_id}')`);
        const res = await axiosNodeClient.post('/retrieve-client-names', 
          JSON.stringify({pb_i: branch_id.current}),
          {
            headers: {
                'content-type': 'application/json'
            }
          }
        );

        const clientNameArr = JSON.parse(res.data.data);
        setClientNamesArr([...new Set(clientNameArr)]) // remove duplicates
      } catch(err) {
        //console.log(err.message)
      }
    }

    getClientNames();
  
  }, [])

  const model = new Model(buildTreatmentAuthJson({
    client_names: clientNamesArr
  }));

  // finish here after refactoring api calls
  const surveyComplete = async (sender) => {
    try {
      setSubmissionState("loading")
      setFormComplete(true);
      let email, first_name, last_name;

      if (sender.data['clientlookup']?.length > 0) {
        let clientNameArr = sender.data['clientlookup'].split(' - ').map(value => {
          return value.trim();
        });

        email = clientNameArr[clientNameArr.length - 1];
        first_name = clientNameArr[0].split(" ")[0];
        last_name = clientNameArr[0].split(" ")[1];
      } else {
        const clientData = sender.data['clientdata']
        email = clientData['email'].trim();
        first_name = clientData['first_name'].trim();
        last_name = clientData['last_name'].trim();
      }

      const therapistObj = JSON.parse(localStorage.getItem('fmpfp_therapist_obj'));
      // axiosNodeClient.defaults.headers['authorization'] = localStorage.getItem('auth_code');

      // call get all clients from phorest and check
      let phorestClientRes = await axios.get(`${API_URL}/get-phorest-clients?email=${encodeURIComponent(email)}`);
      phorestClientRes = JSON.parse(phorestClientRes.data.data);

      isExistingClientPhorest.current = phorestClientRes.is_existing_client;

      if (isExistingClientPhorest.current) {
        phorestClientObj.current = phorestClientRes.client_obj;
      }

      // get all clients from backend and check
      let clientRes = await axiosNodeClient.post(`/retrieve-backend-clients?email=${encodeURIComponent(email)}`, 
        JSON.stringify({pb_i: branch_id.current}),
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      );

      clientRes = JSON.parse(clientRes.data.data);
      isExistingClient.current = clientRes.is_existing_client;

      if (isExistingClient.current) {
        clientObj.current = clientRes.client_obj;
      }

      //write session and insert into phorest client notes
      if (!isExistingClient.current) {

        // post client to backend 
        const createClientPayload = {
          "first_name": first_name,
          "last_name": last_name,
          "email": email,
          "data_consent": false,
          "prescription_email_consent": false,
          "promo_consent": false
        }

        try {
          if (sender.data["consent-data-sharing"].length > 0) {
            createClientPayload["data_consent"] = true;
          }
  
          if (Object.keys(sender.data).includes("consent-emails")) {
            sender.data["consent-emails"].forEach(consent => {
              if (consent === "advice_consent") {
                createClientPayload["prescription_email_consent"] = true;
              } else if(consent === "subscribe_consent") {
                createClientPayload["promo_consent"] = true;
              }
            })
          } 
        } catch {
          console.error('client consent not provided')
        }

        const createClientRes = await axiosNodeClient.post(`/create-backend-client?pb_i=${branch_id.current}`, createClientPayload);
        clientObj.current = JSON.parse(createClientRes.data.data);
      } 

      if (!isExistingClientPhorest.current) {
        // post to express route and write to phorest api
        const clientData = sender.data.clientdata;

        let createClientPayload;

        if (clientData) {
          createClientPayload = {
            "firstName": first_name,
            "lastName": last_name,
            "email": email,
            "birthDate": clientData.hasOwnProperty("birthday") ? clientData.birthday : "",
            "address": {
              "city": clientData.hasOwnProperty("city") ? clientData.city : "",
              "postalCode": clientData.hasOwnProperty("zip") ? clientData.zip: "",
              "state": clientData.hasOwnProperty("state") ? clientData.state : "",
              "streetAddress1": clientData.hasOwnProperty("address") ? clientData.address : "",
              "streetAddress2": clientData.hasOwnProperty("apt_unit") ? clientData.apt_unit : ""
            }
          }
        } else {
          createClientPayload = {
            "firstName": first_name,
            "lastName": last_name,
            "email": email
          }
        }

        const postClientRes = await axios.post(`${API_URL}/create-phorest-client`, createClientPayload)
        phorestClientObj.current = JSON.parse(postClientRes.data.data)
      }
      
      // send signature imgs to s3
      let signature_url;
      let form_data_obj = {...sender.data}

      try {

        // upload client signature 
        const upload_cust_sig_to_s3 = await axiosNodeClient.post('/upload-image-to-bucket', {
          folder: "signature",
          img_string: sender.data['consent_signature']
        });

        signature_url = upload_cust_sig_to_s3.data.img;
        form_data_obj['consent_signature'] = signature_url;

        // upload PST signature
        const upload_pst_sig_to_s3 = await axiosNodeClient.post('/upload-image-to-bucket', {
          folder: "signature",
          img_string: sender.data['pst_signature']
        });

        signature_url = upload_pst_sig_to_s3.data.img;
        form_data_obj['pst_signature'] = signature_url;

      } catch (err) {
        //console.log(err.message)
      }

      // POST session to backend
      const payload = {
        "data": {
          "form_data": form_data_obj
        },
        "client_id": clientObj.current.id,
        "phorest_staff_id": therapistObj.ps_i,
        "phorest_branch_id": therapistObj.pb_i,
        "phorest_client_id": phorestClientObj.current.clientId,
      }

      const sessionRes = await axiosNodeClient.post(`/create-backend-session?pb_i=${branch_id.current}`, payload);
      clientSessionId.current = JSON.parse(sessionRes.data.data).id;

      // post to phorest client notes our backend session response
      const clientNotes = "";

      if (phorestClientObj.current.notes) {
        phorestClientObj.current.notes = phorestClientObj.current.notes += ` ${FRONTEND_URL}/consultation-session/${clientSessionId.current}`;
      } else {
        phorestClientObj.current.notes = `${FRONTEND_URL}/consultation-session/${clientSessionId.current}`;
      }

      const updateClientNotesRes = await axios.post(`${API_URL}/update-phorest-client-notes`, phorestClientObj.current)
      
      setClientSubmissionData({
        ...payload.data,
        pst_name: localStorage.getItem('fmpfp_therapist_obj').fn,
        salon_name: localStorage.getItem('b_n')
      })

      props.setNewForm(true);
    } catch(err) {
      console.log(err)
      // localStorage.removeItem('fmpfp_therapist_obj');
      // localStorage.removeItem('auth_code');
      // //console.log(err)
      // redirect('/');
    }
  }

  model.onComplete.add(surveyComplete);

  function renderContent() {
    // const sendSendgridBtn = 
    // <div className="fmpfp_consultation_email_btn" onClick={(e) => handleSendgridEmail(e)}>
    //   <p className="fmpfp_consultation_email_btn_text">
    //     email form results
    //   </p>
    // </div>

  const form_submitted_text = 
    <p className="fmpfp_consultation_complete_text">
      Thank you for completing the authorization form.
    </p>

  const loadingIcon = 
    <div className="lds-roller fmpfp_consultation_roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    if (!formComplete) {
      return <Survey model={model} />;
    }

    if (formComplete) {
      switch (submissionState) {
        case 'loading':
          return loadingIcon;
        // case 'dataReady':
        //   return [form_submitted_text, sendSendgridBtn];
        case 'submitted':
        default:
          return form_submitted_text;
      }
    }
  }

  return (
    <div id="fmpfp_treatment_auth_container" className="fmpfp_form_component">
      <GeneratePreAuth />
      {renderContent()}
    </div>
  );
}

export default TreatmentAuth; 