import {useState, useEffect} from 'react';
import TreatmentAuth from '.././forms/TreatmentAuth'
import Nav from "../Nav";
import { v4 as uuidv4 } from "uuid";

function TreatmentAuthPage() {
    const [newForm, setNewForm] = useState(null);
    const [displaySubmitModal, setDisplaySubmitModal] = useState(false); 

    useEffect(() => {
        if (newForm) {
            setDisplaySubmitModal(true);

            setTimeout(() => {
                setDisplaySubmitModal(false);
            }, 3000)
        }
    }, [newForm])


    const formSubmitSuccess = <div className="fmpfp_form_submit_success">
        <p id="fmpfp_form_submit_success_text">Treatment Authorization form successfully submitted</p>
    </div>

    return (
        <div className="fmpfp_treatment_auth_page">
            <Nav />
            <TreatmentAuth key={!newForm ? "" : uuidv4()} setNewForm={setNewForm}/>
            {displaySubmitModal ? formSubmitSuccess : null}
        </div>
    );
}

export default TreatmentAuthPage;