import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import SkinGoals from ".././SkinGoals";
import Nav from ".././Nav";
import Consultation from ".././forms/Consultation";
import { skinGoalsImgContext } from "../../variables";


function FmpLandingPage() {
  const [newPlan, setNewPlan] = useState();
  const [toggleBtn, setToggleBtn] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [skinGoalsDesc, setSkinGoalsDesc] = useState(null);
  const [skinGoalsObj, setSkinGoalsObj] = useState(null);

  useEffect(() => {
    const skinGoalsString = skinGoalsDesc ? skinGoalsDesc.slice(0) : "";
    localStorage.setItem("skin_goals_desc", skinGoalsString)
  }, [skinGoalsDesc])

  function toggleNewPlan() {
    setLoadingSpinner(true);
    setTimeout(() => {
      setNewPlan(true);
      setLoadingSpinner(false);

      localStorage.removeItem("sv_cfd")
      localStorage.removeItem("skin_icons")
      localStorage.removeItem("skin_goals_desc")
      localStorage.removeItem("skin_icons_text_arr")
    }, 500);
  }

  // window.toggleNewPlan = toggleNewPlan;

  function uuid() {
    setNewPlan(false);
    return uuidv4();
  }

  function handleToggleBtn() {
    setToggleBtn(true);
  }
  return (
    <div className="fmpfp_landing_page">
      <Nav />
      {loadingSpinner ? (
        <i className="fas fa-circle-notch fa-spin fa-2xl"></i>
      ) : (
        <div className="fmpfp_landing_page_inner_container">
          <button onClick={toggleNewPlan} className="fmpfp_skin_goals_toggle" id="fmpfp_app_toggle">
            new skin fitness plan
          </button>

          <SkinGoals setSkinGoalsDesc={setSkinGoalsDesc} key={!newPlan ? "" : uuid()} />
          <Consultation skinGoalsDesc={skinGoalsDesc} />
        </div>
      )}
    </div>
  );
}

export default FmpLandingPage;