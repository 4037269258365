import { useSearchParams } from 'react-router-dom';
import PreTreatmentAuth from '../forms/PreTreatmentAuth'
import Nav from "../Nav";
import {axiosNodeClient} from '../../variables';
import { useState, useEffect } from 'react';

function PreTreatmentAuthPage() {

    const [pstMessage, setPstMessage] = useState(null);
    const [pstNote, setPstNote] = useState(null);
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ authId, setAuthId ] = useState("");
    const [ pstId, setPstId ] = useState("");
    const [ branchId, setBranchId ] = useState("");

    useEffect(() => {
        setAuthId(searchParams.get('c_i'));
        setPstId(searchParams.get('t_i'));
        setBranchId(searchParams.get('pb_i'));
    }, [])

    useEffect(() => {
        try {
            // call getAuthData here
            if (authId && pstId && branchId) {
                getAuthData();
            }
        } catch (err) {
            console.log(err.message);
        }
    }, [authId, pstId, branchId])

    async function getAuthData() {
        try {
            // call node endpoint here
            const response = await axiosNodeClient.get(`/get-auth-message?pb_i=${branchId}&c_i=${authId}`);
            const combinedMsg = JSON.parse(response.data.data).message;

            // extract Pst Msg & Note from msg string
            let extractedPstMsg = combinedMsg.split(" --- ")[0];
            extractedPstMsg = extractedPstMsg.split("Message: ")[1];

            let extractedPstNote = combinedMsg.split(" --- ")[1];
            extractedPstNote = extractedPstNote.split("Note: ")[1];

            if (extractedPstMsg !== "undefined") {
                setPstMessage(extractedPstMsg);
            }

            if (extractedPstNote !== "undefined") {
                setPstNote(extractedPstNote);
            } 
            
        } catch (err) {
            console.log(err.message)
        }
    }
    
    return (
        <div className="fmpfp_treatment_auth_page">
            {
                pstMessage ?
                    <div id="fmpfp_treatment_auth_pst_msg_container">
                        <p id="fmpfp_treatment_auth_pst_msg">{pstMessage}</p>
                    </div>
                :
                    null                
            }
            <PreTreatmentAuth pstNote={pstNote} pb_i={branchId} ps_i={pstId}/>
        </div>
    );
}

export default PreTreatmentAuthPage;