import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import fmp_logo from "../images/logo.png";
import derm_logo from "../images/dermalogica logo.png";
import { axiosNodeClient } from "../variables";

function Nav() {
    const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
    const [pstName, setPstName] = useState("");
    const [pb_i, set_pb_i] = useState();
    const [userMenuActive, setUserMenuActive] = useState(false);
    const [pstObjContainer, setPstObjContainer] = useState();

    // close the hamburger menu when the user clicks outside of the hamburger menu
    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (e.target.className !== "fmpfp_hamburger_menu" && e.target.className !== "fmpfp_hamburger_menu_content" && e.target.className !== "fmpfp_hamburger_menu_item") {
                setHamburgerMenuOpen(false);
            }
        });

        try {
            const pstObj = JSON.parse(localStorage.getItem("fmpfp_therapist_obj"))
        
            setPstName(pstObj["fn"])
            set_pb_i(pstObj["pb_i"])
        } catch {
            // do nothing
        }
        
    }, []);

    // call api to get all therapists using branch_id
    // filter to exclude current staff_id
    // save to state
    // map over state to create dropdown options
    // on select replace current therapistObj in local storage with new therapistObj


    // close the hamburger menu when the screen is resized
    useEffect(() => {
        window.addEventListener("resize", () => {
            setHamburgerMenuOpen(false);
        });

        const getAllPstFromBranch = async () => {
            try {
                let res = await axiosNodeClient.get(`/get-all-therapists?pb_i=${JSON.parse(localStorage.getItem("fmpfp_therapist_obj"))["pb_i"]}`);
                res = JSON.parse(res.data.data);

                const allPstObj = new Map();

                res.forEach(pstObj => {
                    allPstObj.set(pstObj.phorest_staff_id, {
                        fn: pstObj.first_name,
                        ln: pstObj.last_name,
                        ps_i: pstObj.phorest_staff_id,
                        // pb_i: pstObj.phorest_branch_id,
                        // ps_si: pstObj.phorest_staff_id
                    })
                })

                setPstObjContainer(allPstObj);
            } catch(err) {
                
            }
        }

        getAllPstFromBranch();
    }, []);

    function handleLogout() {
        localStorage.removeItem("fmpfp_therapist_obj");
        localStorage.removeItem("auth_code");
        localStorage.removeItem("b_n");

        window.location.pathname = "/";
    }

    function handleUserMenu() {
        setUserMenuActive(!userMenuActive)
    }

    async function handlePstAccount(ps_i) {
        let backend_res = await axiosNodeClient.get(`/get-therapist-from-id?ps_i=${ps_i}&pb_i=${pb_i}`);
        backend_res = JSON.parse(backend_res.data.data);
        //console.log(backend_res);

        localStorage.setItem("fmpfp_therapist_obj", JSON.stringify(backend_res));
        window.location.reload();
    }

    function loadPstEl() {
        const pstElContainer = [];

        for (const [key, pstObj] of pstObjContainer) {
            pstElContainer.push(
                <div className="fmpfp_hamburger_menu_item" onClick={() => handlePstAccount(pstObj.ps_i)} >
                    <p className="fmpfp_hamburger_menu_text">{pstObj.fn} {pstObj.ln}</p>
                </div>
            );
        }

        return pstElContainer;
    }

    return (
        <div className="fmpfp_nav">
            <div className="fmpfp_nav_content">
                <div className="fmpfp_nav_logo_container">
                    <Link to="/">
                        <img className="fmpfp_nav_logo" src={fmp_logo} alt="FMP Logo" />
                    </Link>
                </div>
                <p className="fmpfp_nav_pst_name">PST: {pstName}</p>
                <div className="fmpfp_hamburger_menu" onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}>
                    <div className="fmpfp_hamburger_menu_line"></div>
                    <div className="fmpfp_hamburger_menu_line"></div>
                    <div className="fmpfp_hamburger_menu_line"></div>
                </div>
                {!userMenuActive ? 
                    <div className={hamburgerMenuOpen ? "fmpfp_hamburger_menu_content fmpfp_hamburger_menu_content_open" : "fmpfp_hamburger_menu_content"}>
                        <div className="fmpfp_hamburger_menu_item">
                            <Link to="/">new consultation form</Link>
                        </div>
                        {/* <div className="fmpfp_hamburger_menu_item">
                            <Link to="/authorization-form">new authorization form</Link>
                        </div> */}
                        <div className="fmpfp_hamburger_menu_item" onClick={handleUserMenu}>
                            <p className="fmpfp_hamburger_menu_text" style={{fontWeight: '400'}}>switch user</p>
                        </div>
                        <div className="fmpfp_hamburger_menu_item" onClick={handleLogout}>
                            <Link to="/">log out</Link>
                        </div>
                    </div>
                : 
                    <div className={hamburgerMenuOpen ? "fmpfp_hamburger_menu_content fmpfp_hamburger_menu_content_open" : "fmpfp_hamburger_menu_content"} id="fmpfp_user_menu">
                        <div className="fmpfp_hamburger_menu_item" onClick={handleUserMenu}>
                            <p className="fmpfp_hamburger_menu_text" style={{fontWeight: '400'}}>back</p>
                        </div>
                        {pstObjContainer ? loadPstEl() : null}
                    </div>
                }
            </div>
        </div>
    ); 
}

export default Nav;