import React, { useState, useEffect, useRef} from "react";
import { Navigate } from 'react-router-dom';
import "./css/styles.min.css";
import FmpLoginPage from './components/pages/FmpLoginPage'
import FmpLandingPage from "./components/pages/FmpLandingPage";
import TreatmentAuthPage from "./components/pages/TreatmentAuthPage"
import PreTreatmentAuthPage from "./components/pages/PreTreatmentAuthPage"
import FmpConsultSessPage from "./components/pages/FmpConsultSessPage";
import { Route, Routes } from "react-router-dom"
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

function App() {

  const [ currPageDisplayed, setCurrPageDisplayed ] = useState();
  const [ pstLoggedIn, setPstLoggedIn ] = useState(false);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const therapistObj = JSON.parse(localStorage.getItem('fmpfp_therapist_obj'));
    // if (therapistObj && therapistObj.branch_id) {
    //   localStorage.removeItem("fmpfp_therapist_obj");
    // }

    // check if therapist Obj already exists from localstorage
    if (therapistObj) {
      setPstLoggedIn(true);
      setCurrPageDisplayed(<FmpLandingPage />)
    } else {
      setCurrPageDisplayed(<FmpLoginPage />)
    }
  }, [])

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true}
      isVerboseMode={true}
    >
      <div className="App">
      <Routes>
        <Route path="/" element={currPageDisplayed} />
        <Route path="/pre-authorization-form" element={<PreTreatmentAuthPage />} />
        {
            pstLoggedIn ? 
              <Route path="/authorization-form" element={<TreatmentAuthPage />} />
            : 
              <Route path="/authorization-form" element={<Navigate to="/" replace />} />
        }
        {
            pstLoggedIn ? 
                <Route path="/consultation-session/:id" element={<FmpConsultSessPage />} />
            : 
                <Route path="/consultation-session/:id" element={<Navigate to="/" replace />} />
        }
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes> 
      </div>
    </CacheBuster>
  );
}

export default App;
