import React from 'react';
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

// import { surveyTheme } from '../../data/survey_theme';
// StylesManager.applyTheme(surveyTheme);

function DisplaySession ({ surveyJSON, formData }) {
    const model = new Model(surveyJSON);
  
    // Autofill form fields
    // model.data = {
    //     "first_name": formData.first_name || "",
    //     "last_name": formData.last_name || "",
    //     "email": formData.email || "",
    //     "consent-data-sharing": formData['consent-data-sharing'] || [],
    //     "metal_implants": formData.metal_implants,
    //     "pregnancy": formData.pregnancy || "",
    //     "signature": formData.signature || "",
    //     "allergies": formData.allergies || "",
    //     "current_treatments": formData.current_treatments || [],
    //     "consent-emails": formData['consent-emails'] || [],
    //     "My Regimen": formData['My Regimen'] || [],
    //     "allergies_desc": formData['allergies_desc'] || "",
    //     "notes": formData['notes'] || "",
    //     "new-or-returning": formData['new-or-returning'] || "",
    //     "clientlookup": formData['clientlookup'] || "",
    //     "services": formData['services'] || [],
    // };
    
    model.data = {...formData};

    //console.log(formData)

    // model.data = {};

    // Object.keys(formData).forEach((key, index) => {
    //     model.data[key] = formData[key];
    //     console.log(model.data[key])
    // })
    
    // disable inputs and hide submit btn
    model.onAfterRenderQuestion.add((survey, options) => {
        const sessionContainer = document.querySelector('.fmpfp_display_session_container');

        if (options.question.getType() === "paneldynamic") {
            const button = sessionContainer.querySelectorAll("input[type='button']").forEach(button => button.style.display='none');
        }

        sessionContainer.querySelectorAll('input').forEach(inputEl => inputEl.disabled = 'disabled')    
        sessionContainer.querySelectorAll('textarea').forEach(inputEl => inputEl.disabled = 'disabled')
        sessionContainer.querySelectorAll('.sjs_sp_clear, .sd-dropdown_clean-button').forEach(clearBtn => clearBtn.style.display = 'none')

        const regimenContainer = document.querySelector("div[data-key='regimen_panel0']");
        regimenContainer.querySelectorAll('.sd-paneldynamic__remove-btn, .sd-paneldynamic__add-btn').forEach(inputEl => inputEl.style.display = 'none')

        regimenContainer.querySelectorAll('.sd-selectbase').forEach(inputEl => inputEl.pointerEvents = 'none')
    });
  
    return (
        <div className="fmpfp_display_session_container">
            <Survey model={model} />
        </div>
    )
};
  
export default DisplaySession;