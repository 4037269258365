import { useState, useRef, useEffect } from 'react';
import { axiosNodeClient } from '../variables';

function GeneratePreAuth() {
    const [displayGenerateContainer, setDisplayGenerateContainer] = useState(false);
    const [currContainerPage, setCurrContainerPage] = useState(0);
    const [pstMessage, setPstMessage] = useState("");
    const [pstNote, setPstNote] = useState("");
    const [collectionId, setCollectionId] = useState("");
    const [createdPreAuthUrl, setCreatedPreAuthUrl] = useState("");
    const [t_i, set_t_i] = useState("");
    const [pb_i, set_pb_i] = useState("");

    const generatePreAuthContainer = useRef(null);
    const backdrop = useRef(null);
    const containerPageOne = useRef(null);
    const containerPageTwo = useRef(null);
    const containerPageThree = useRef(null);
    const copyBtn = useRef(null);

    const pageArr = [containerPageOne, containerPageTwo, containerPageThree];

    useEffect(() => {
        generatePreAuthContainer.current.style.display = displayGenerateContainer ? 'flex' : 'none';
        backdrop.current.style.display = displayGenerateContainer ? 'flex' : 'none';

        try {
            const therapist_id = JSON.parse(localStorage.getItem("fmpfp_therapist_obj")).t_i;
            const pb_i = JSON.parse(localStorage.getItem("fmpfp_therapist_obj")).pb_i

            set_t_i(therapist_id);
            set_pb_i(pb_i)
        } catch {
            console.log("error fetching PST info")
        }

    }, [displayGenerateContainer])

    useEffect(() => {
        pageArr.forEach((page, index) => {
            if (index === currContainerPage) {
                page.current.style.display = 'flex';
            } else {
                page.current.style.display = 'none';
            }
        })

        const callBackend = async () => {
            if (currContainerPage === 2) {
                // add backend logic here
                if (pstMessage || pstNote) {
                    await handleMessage();
                }
            }
        }

        callBackend()

    }, [currContainerPage])

    useEffect(() => {
        createPreAuthUrl();
    }, [collectionId])

    const handleMessage = async () => {
        try {
            // send message to backend
            const payload = {
                therapist_id: t_i,
                message: `Message: ${pstMessage} --- Note: ${pstNote}`
            }
            
            const res = await axiosNodeClient.post(`/handle-auth-message?pb_i=${pb_i}`, payload);
            const collectionId = JSON.parse(res.data.data).id;

            // set id from response
            setCollectionId(collectionId);

            return;
        } catch (err) {
            console.log(err.message)
        }
    }

    const createPreAuthUrl = () => {
        try {
            if (pstMessage || pstNote) {
                const preAuthUrl = `${process.env.REACT_APP_FRONTEND_URL}/pre-authorization-form?c_i=${collectionId}&t_i=${t_i}&pb_i=${pb_i}`;
                setCreatedPreAuthUrl(preAuthUrl);
            } else {
                setCreatedPreAuthUrl(`${process.env.REACT_APP_FRONTEND_URL}/pre-authorization-form?t_i=${t_i}&pb_i=${pb_i}`)
            }
        } catch {
            // do nothing
        }
    }


    const handleBtnsRender = () => {
        if (currContainerPage === 0) {
            return <button className="fmpfp_generate_pre_auth_toggle_btn fmpfp_pre_auth_btn_blue" id="fmpfp_generate_pre_auth_continue" onClick={handleNextPage}>continue</button>   
        } else if (currContainerPage === 1) {
            return (
                <div>
                    <button className="fmpfp_generate_pre_auth_toggle_btn fmpfp_pre_auth_btn_clear" id="fmpfp_generate_pre_auth_back" onClick={handlePrevPage}>back</button>
                    <button className="fmpfp_generate_pre_auth_toggle_btn fmpfp_pre_auth_btn_blue" id="fmpfp_generate_pre_auth_create" onClick={handleNextPage}>create</button>
                </div>
            )
        } else {
            return <button className="fmpfp_generate_pre_auth_toggle_btn fmpfp_pre_auth_btn_blue" id="fmpfp_generate_pre_auth_copy" ref={copyBtn} onClick={handleCopyClick}>copy</button>
        }
    }

    const handleNextPage = () => {
        const nextPage = currContainerPage + 1;
        setCurrContainerPage(nextPage);
    }

    const handlePrevPage = () => {
        const prevPage = currContainerPage - 1;
        setCurrContainerPage(prevPage);
    }

    const handleCloseContainer = () => {
        setDisplayGenerateContainer(false);
    }

    const handleCopyClick = () => {
        navigator.clipboard.writeText(createdPreAuthUrl);
        copyBtn.current.innerText = "copied!";

        setTimeout(() => {
            copyBtn.current.innerText = "copy";
        }, 3000)
    }

    return (
        <div className="fmpfp_generate_pre_auth">
            <button id="fmpfp_generate_pre_auth_btn" 
                onClick={() => setDisplayGenerateContainer(!displayGenerateContainer)}
            >
                generate pre-treatment authorization form
            </button>
            <div id="fmpfp_pre_auth_outer_container" class="fmpfp_generate_pre_auth_outer_container" ref={backdrop}></div>
            <div id="fmpfp_generate_pre_auth_container" ref={generatePreAuthContainer}>
                    <div id="fmpfp_generate_pre_auth_header">
                        <h1 id="fmpfp_generate_pre_auth_title">generate Treatment Authorization link</h1>
                        <button id="fmpfp_generate_pre_auth_exit" onClick={handleCloseContainer}>x</button>
                    </div>
                    <div id="fmpfp_generate_pre_auth_content">
                        <div id="fmpfp_generate_pre_auth_progress"></div>
                        <div className="fmpfp_generate_pre_auth_p" id="fmpfp_generate_pre_auth_p1" ref={containerPageOne}>
                            <h3 className="fmpfp_generate_pre_auth_content_header">Include message</h3>
                            <label htmlFor="fmpfp_generate_pre_auth_message_input" className="fmpfp_generate_pre_auth_prompt">Type a message to your client here...</label>
                            <textarea className="fmpfp_generate_pre_auth_input" name="fmpfp_generate_pre_auth_input" id="fmpfp_generate_pre_auth_message_input" cols="30" rows="10" onChange={(evt) => setPstMessage(evt.target.value)}></textarea>
                        </div>
                        <div className="fmpfp_generate_pre_auth_p" id="fmpfp_generate_pre_auth_p2" ref={containerPageTwo}>
                        <h3 className="fmpfp_generate_pre_auth_content_header">Note to self</h3>
                            <label htmlFor="fmpfp_generate_pre_auth_note_input" className="fmpfp_generate_pre_auth_prompt">Type a message to your client here...</label>
                            <textarea className="fmpfp_generate_pre_auth_input" name="fmpfp_generate_pre_auth_input" id="fmpfp_generate_pre_auth_note_input" cols="30" rows="10" onChange={(evt) => setPstNote(evt.target.note)}></textarea>
                        </div>
                        <div className="fmpfp_generate_pre_auth_p" id="fmpfp_generate_pre_auth_p3" ref={containerPageThree}>
                            {/* <h3 className="fmpfp_generate_pre_auth_content_header">Copy link</h3> */}
                            <h1 id="fmpfp_generate_pre_auth_complete_title">Treatment Authorization link has been successfully created</h1>
                            <label htmlFor="fmpfp_generate_pre_auth_note_input" className="fmpfp_generate_pre_auth_prompt">Copy this link and send it to your client via email or message</label>
                            <textarea className="fmpfp_generate_pre_auth_input" name="fmpfp_generate_pre_auth_input" id="fmpfp_generate_pre_auth_note_input" cols="30" rows="10" value={createdPreAuthUrl}></textarea>
                        </div>
                        <div className="fmpfp_generate_pre_auth_toggle_btn_container">
                            {handleBtnsRender()}
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default GeneratePreAuth;