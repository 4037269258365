export const branchCategoryMappings = {
    "Dermalogica - Duke of York": {
        "clientcategoryid": "UA8_RvEjAaAjgSn7FwOXMw"
    },
    "Dermalogica - Fenwick Colchester": {
        "clientcategoryid": "n7uSn8XKXmMThdE1vjPJpg"
    },
    "Dermalogica - H Beauty Bristol": {
        "clientcategoryid": "_4WKFNkL38jn8eSIZKuSAw"
    },
    "Dermalogica - Next Beauty Gateshead": {
        "clientcategoryid": "1Ocf_9DFJUx__fRSE5vbTA"
    },
    "Sephora": {
        "clientcategoryid": "fKikiKYnoIlqvzY4d6b85A"
    },
    "Westfield Shepherds Bush": {
        "clientcategoryid": "oRQjmtQNif2T4Tv8u5LY2g"
    },
    "A Dermalogica Testing": {
        "clientcategoryid": "LRrLsxUfWMMSY6huiB31tg"
    },
    "Dermalogica - Belfast": {
        "clientcategoryid": "PcTTZi5TfCGK9jObD23_Gw"
    },
    "Bentalls Kingston": {
        "clientcategoryid": "zOJZu8fejdzUhCODwDZPXA"
    },
    "Dermalogica - Bluewater": {
        "clientcategoryid": "-s_rw9gm2pQ_lRDz-jIQSw"
    },
    "Dermalogica": {
        "clientcategoryid": "LmcxgIrKo2kNfgHOzedPFA"
    },
    "Elys": {
        "clientcategoryid": "sYUz1a_ePCoYFsJst68sHg"
    },
    "Fenwick Brent Cross": {
        "clientcategoryid": "TdjfPGRLiw2cKh8I55s4Fg"
    },
    "Fenwick Newcastle": {
        "clientcategoryid": "k_q2pq89EcPwv3kn807GNA"
    },
    "Dermalogica - Flannels Liverpool": {
        "clientcategoryid": "01I7HuHRZFo8A2tQxGGLXQ"
    },
    "Dermalogica - Glasgow": {
        "clientcategoryid": "Ldnd3iNFLshSNSsksBfuNw"
    },
    "H Beauty Lakeside": {
        "clientcategoryid": "j8Sml-zmNsQgDIEEYP1XFw"
    },
    "H Beauty Milton Keynes": {
        "clientcategoryid": "esbRu4wWZJOcL3LfaEe4Yw"
    },
    "H Beauty Newcastle": {
        "clientcategoryid": "DivYQ2_J92cSvsw4BjmwDw"
    },
    "Harrods": {
        "clientcategoryid": "Rh9xZJLcwijzz-OBonEueQ"
    },
    "Harvey Nichols Edinburgh": {
        "clientcategoryid": "e9au7gvXM0qdE-sqn0jGDw"
    },
    "Harvey Nichols Knightsbridge": {
        "clientcategoryid": "7lUqQ7VjmcsFEhxztqvsQg"
    },
    "Harvey Nichols Leeds": {
        "clientcategoryid": "bAvDV7_I7_c7E5RDnL_g6g"
    },
    "Harvey Nichols Manchester": {
        "clientcategoryid": "JLqqkQjb8XabMEiQQGs66w"
    },
    "John Bell and Croyden": {
        "clientcategoryid": "sgEIeMhXy8Rko9i16Lyfcg"
    },
    "John Lewis Cheadle": {
        "clientcategoryid": "yDUsXJ8GC6Cce_pNm2I36Q"
    },
    "John Lewis Cheltenham": {
        "clientcategoryid": "rmm_icPnnlnqu1AmZ5-u0Q"
    },
    "John Lewis Liverpool": {
        "clientcategoryid": "I4xJPpueOhXITB_HTKnxvg"
    },
    "John Lewis Oxford Street": {
        "clientcategoryid": "v3JK-ir8xHKf-vRLOe_EEA"
    },
    "John Lewis Reading": {
        "clientcategoryid": "szAQERB6GnJU6VKPC-1DtQ"
    },
    "John Lewis Southampton": {
        "clientcategoryid": "k81PFNEBeAyR2yabDhS-kg"
    },
    "John Lewis Stratford": {
        "clientcategoryid": "7y9pRT7nBrN3AdbCHwVGpA"
    },
    "Liberty": {
        "clientcategoryid": "2TVCIL0ofnBlO2XHoFwNdw"
    },
    "Meadowhall": {
        "clientcategoryid": "0bStlMUxVJ4pxIP8s0a7Eg"
    },
    "Dermalogica - Next Beauty MK": {
        "clientcategoryid": "jonZowE8PdajAnMqT581NQ"
    },
    "Dermalogica - One New Change": {
        "clientcategoryid": "sGFo8GaPCZcpAsH9-TSc4Q"
    },
    "Selfridges Birmingham": {
        "clientcategoryid": "uIhA3JXY7szyqobZzqWFAQ"
    },
    "Selfridges Oxford Street": {
        "clientcategoryid": "WYnPE3J5Mw9v18RBPAZaIg"
    },
    "Selfridges Trafford": {
        "clientcategoryid": "ZWEgq-DvXb18Oa-vl1wblQ"
    },
    "Voisins": {
        "clientcategoryid": "_AbC2vADzoE-9VKVKzHZbg"
    },
    "Dermalogica - Flannels Metrocentre": {
        "clientcategoryid": "dUXg8j1Gjf8aJmSzYGHWEw"
    },
    "Dermalogica - Sephora Stratford": {
        "clientcategoryid": "vhdWu2bGxlPlP77MNQqT1g"
    }
}
