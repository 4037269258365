import axios from 'axios';
import { createContext } from 'react';

export const API_URL = process.env.REACT_APP_CLIENT_API_URL
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL


// axios config function
export const axiosNodeClient = axios.create({
    baseURL: API_URL,
    headers: {
        "content-type": "application/json",
    },
    data: null,
    proxy: undefined
});

