import React, {useEffect, useState, useRef} from 'react';
import {axiosNodeClient} from '../../variables';
import DisplaySession from '../forms/DisplaySession';
import Nav from '../Nav';
import { buildConsultationJson } from '../../data/json-forms/Consultation_Form';
import { Link } from "react-router-dom"


function FmpDisplaySessionPage() {
    const [sessionDataObj, setSessionDataObj] = useState(null);
    const sessionId = useRef(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [sessionFailed, setSessionFailed] = useState(false);
    const [displayPinInput, setDisplayPinInput] = useState(true);
    const [pinValue, setPinValue] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    
    const submitBtn = useRef(null);


    const ConsultationJson = buildConsultationJson();

    async function getSession() {
        try {
            const validatedPst = await getTherapist();

            if (validatedPst) {
                let sessionData = await axiosNodeClient.post(`/retrieve-backend-session`, 
                    JSON.stringify({
                        session_id: sessionId.current,
                        // pb_i: JSON.parse(localStorage.getItem("fmpfp_therapist_obj"))["pb_i"]
                    },
                    {
                        headers: {
                            'content-type': 'application/json'
                        }
                    }
                ));

                sessionData = JSON.parse(sessionData.data.data)[0];
                
                let signatureBase64 = await getBase64Image(sessionData.signature);
                
                if (signatureBase64) {
                    sessionData.signature = signatureBase64;
                }

                setSessionDataObj(sessionData);
                setPageLoading(false);
                setDisplayPinInput(false)
            } else {
                setShowErrorModal(true);

                setTimeout(() => {
                    setShowErrorModal(false);
                }, 3000)
            }
        } catch (err) {
            setSessionFailed(true)
        }
    }

    async function getTherapist() {
        try {            
            const ps_i = JSON.parse(localStorage.getItem("fmpfp_therapist_obj"))["ps_i"];
            const res = await axiosNodeClient.get(`/validate-therapist?ps_i=${ps_i}&p=${pinValue}`)

            if (res?.data.validated) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    useEffect(() => {
        const pstObj = JSON.parse(localStorage.getItem("fmpfp_therapist_obj"))
        if (!pstObj) {
            window.location.href = "/";
        }
        sessionId.current = window.location.pathname.split("/")[2]; 
        //* getSession(); re-enable later
        
        document.querySelectorAll('input').forEach(input => {
            if (input.id !== "fmpfp_consultation_pin_input") input.disabled = 'true';
        })

        document.querySelectorAll('textarea').forEach(textarea => {
            textarea.disabled = 'true';
        })

        document.querySelectorAll('canvas').forEach(canvas => {
            canvas.pointerEvents = 'none';
        })

        //console.log( document.querySelectorAll('input'))
    }, [])

    async function getBase64Image(imageUrl) {
        let imgBase64 = "";

        try {
            await fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                imgBase64 = base64String;
            };
            reader.readAsDataURL(blob);
            });
        return imgBase64;            
        } catch (err) {
            return null;
        }

    }

    useEffect(() => {
        if (pinValue.length === 5) {
            submitBtn.current.classList.remove('fmpfp_consultation_pin_submit_disabled');
        } else {
            submitBtn.current.classList.add('fmpfp_consultation_pin_submit_disabled');
        }
    }, [pinValue])

    

    function handleRender() {
        if (sessionFailed) {
            return (
                <div className="fmpfp_consult_sess_err_container">
                    <div id="fmpfp_consult_sess_err_modal">
                        <p id="fmpfp_consult_sess_err_msg">Invalid session or branch does not match</p>
                        <Link to="/">
                            <button id="fmpfp_consult_sess_err_btn">
                                return to homepage
                            </button>
                        </Link>
                    </div>
                </div>
            )
        } else if (displayPinInput) {
            return (
                <div className="fmpfp_consultation_sess_pin_outer_container">
                    <div className="fmpfp_consultation_sess_pin_inner_container">
                        <div className="fmpfp_consultation_sess_content">
                            <label id="fmpfp_consultation_sess_pin_text" htmlFor="fmpfp_session_pin_input">Enter PST PIN:</label>
                            <input id="fmpfp_consultation_pin_input" type="password" maxLength={5} name="fmpfp_sess_pin_input" onChange={(evt) => setPinValue(evt.target.value)} />
                            <button ref={submitBtn} id="fmpfp_consultation_pin_submit" className="fmpfp_consultation_pin_submit_disabled" onClick={getSession}>
                                <p>submit</p>
                            </button>
                        </div>
                    </div>
                    {
                        showErrorModal ? 
                            <div className="fmpfp_login_failed_container">
                                <p id="fmpfp_login_failed_text">Invalid PIN entered</p>
                            </div>
                        : null
                    }
                </div>
            )
        } else {
            return (
                pageLoading ? <i className="fas fa-circle-notch fa-spin fa-2xl"></i> :
                <div className="fmpfp_consult_sess_page_content">
                    <div className="fmpfp_sess_skin_goals_container">
                        <label id="fmpfp_skin_goals_title">my skin goals</label>
                        <img className="fmpfp_sess_skin_goals_img" src={sessionDataObj.data.skin_goals.skin_goals_img || `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${process.env.REACT_APP_AWS_FOLDER_NAME}/fmpx_skin-fitness_cb718399-d6b2-461a-ad61-a55a0ff6c3dc.jpg`} />

                        {
                            sessionDataObj.data.skin_goals.skin_goals_desc ? 
                            <p className="fmpfp_sess_skin_goals_desc">Skin Goals: {sessionDataObj.data.skin_goals.skin_goals_desc}</p> : 
                            null
                        }
                    </div>
                    <DisplaySession surveyJSON={ConsultationJson} formData={sessionDataObj.data.form_data}/>
                </div> 
            )
        }
    }

    return (
        <div className="fmpfp_consult_sess_page">
            <Nav />
            {handleRender()}
        </div>
    )
}   

export default FmpDisplaySessionPage;